import React from 'react';
import Search from './search';
import Form from './form';
import moment from 'moment';
import { site } from './config/config';

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      intake_done: false,
      initial_form: null,
      user: null
    }
    this.done = this.done.bind(this);
  }
  componentDidMount() {
    let params = window.location.search;

    if (params) {
      params = new URLSearchParams(params);

      let price = params.get('price'), 
          post_op_patient = params.get('wlc-patient'),
          password_reset = params.get('password_reset'),
          scs_procedure = params.get('procedure');

      if(password_reset && password_reset !== '') {
        console.log('password_reset', password_reset);
        localStorage.clear();
        localStorage.setItem('password-reset-token', password_reset);
        localStorage.setItem('emr-price', 'wlc');
        window.location.href = '/#password_reset';
      }
      else if(post_op_patient && post_op_patient === 'yes') {
        localStorage.clear();
        localStorage.setItem('emr-price', 'wlc');
        window.location.href = '/#login';
      }
      else if (price && price !== '') {
        localStorage.clear();
        localStorage.setItem('emr-price', price);
        if (scs_procedure && scs_procedure !== '') {
          localStorage.setItem('emr-procedure', scs_procedure);
        }
        window.location.href = '/';
      }
    }

    var that = this;
    if(localStorage.getItem('patient_id')) {
      fetch(window.server_url + '/patients/' + localStorage.getItem('patient_id'), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      })
      .then(function (response) {
          if (!response.ok) {
            let price = localStorage.getItem('emr-price'),
                password_reset = localStorage.getItem('password-reset-token');

            console.log('price!', price);
            localStorage.clear();
            if(price) localStorage.setItem('emr-price', price);
            if(password_reset) localStorage.setItem('password-reset-token', password_reset);

            that.setState({initial_form: 'regform-first'});
            //throw Error(response.statusText);
          }
          return response.json();
      })
      .then(function (res) {
        if(!res.error && res.data.next_form) {
          if(res.data.next_form === 'done') {
            let user = {};
            let email = res.data.form['regform-first'].find((e) => {return e.name === 'Email Address'});
            let phone_type = res.data.form['regform-first'].find((e) => {return e.name === 'Best Number to Reach You'})
            let phone_field = res.data.form['regform-first'].find((e) => {return e.name === 'Phone Number'});
            if(!phone_field) phone_field = res.data.form['regform-first'].find((e) => {return e.name === 'Mobile Phone Number'})

            user.previous_no_show = res.previous_no_show;
            user.contact_if_earlier_appt_available = res.data.contact_if_earlier_appt_available;
            user.state = res.data.form['regform-first'].find((e) => {return e.name === 'State'}).value;
            user.first_name = res.data.form['regform-first'].find((e) => {return e.name === 'First Name'}).value;
            user.last_name = res.data.form['regform-first'].find((e) => {return e.name === 'Last Name'}).value;
            user.phone = phone_field.value;
            user.phone_type = phone_type ? phone_type.value : '';
            user.address_1 = res.data.form['regform-first'].find((e) => {return e.name === 'Street Address'}).value;
            user.city = res.data.form['regform-first'].find((e) => {return e.name === 'City'}).value;
            user.state = res.data.form['regform-first'].find((e) => {return e.name === 'State'}).value;
            user.zip = res.data.form['regform-first'].find((e) => {return e.name === 'Zip Code'}).value;
            user.email = email ? email.value : '';
            user.insurance = res.data.form['regform-insurance'] ? res.data.form['regform-insurance'].find((e) => {return e.name === 'Insurance Name'}).value : null;
            user.dob = res.data.form['regform-insurance'] ? res.data.form['regform-insurance'].find((e) => {return e.name === 'Date of Birth'}).value : null;
            return that.done(user);
          } else {
            that.setState({ initial_form: res.data.next_form });
          }
        } else {
          console.log('clear');
            let price = localStorage.getItem('emr-price'),
                password_reset = localStorage.getItem('password-reset-token');

          localStorage.clear();
          if(price) localStorage.setItem('emr-price', price);
          if(password_reset) localStorage.setItem('password-reset-token', password_reset);

          that.setState({initial_form: 'regform-first'});
        }
      })
      .catch(error => console.log(error) );   
    } else {
      let price = localStorage.getItem('emr-price'),
          scs_procedure = localStorage.getItem('emr-procedure'),
          password_reset = localStorage.getItem('password-reset-token');

      localStorage.clear();
      if(price) localStorage.setItem('emr-price', price);
      if(scs_procedure) localStorage.setItem('emr-procedure', scs_procedure);
      if(password_reset) localStorage.setItem('password-reset-token', password_reset);


      this.setState({initial_form: 'regform-first'});
    }
  }
  done(user) {
    let that = this;
    
    console.log('testtest');
    fetch(window.server_url + '/patients/' + localStorage.getItem('patient_id'), {
      method: 'PUT',
      body: JSON.stringify({
        completed_intake: moment().format('YYYY-MM-DD')
      }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    })
    .then(function (response) {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
    })
    .then(function (res) {
      if(localStorage.getItem('emr-price') === 'membership') {
        that.setState({
          intake_done: true,
          user: user
        })

      } else {
        that.setState({
          intake_done: true,
          user: user
        })
      }
    });
    // #TODO: post back to server here that user is done
    
  }
  render() {
    if(!this.state.intake_done && this.state.initial_form) return <Form form={this.state.initial_form} done={this.done} />;
    else if(this.state.intake_done && this.state.user) return <Search postIntakeForm={this.postIntakeForm} paid={this.state.paid} payment={this.payment} user={this.state.user} />
    else return <div className="modal">Loading...</div>;
  }
}

export default App;