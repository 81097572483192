import React, { useState } from "react";
import Cleave from "cleave.js/react";
import moment from "moment-timezone";
import { useParams } from "react-router-dom";
import Popup from "reactjs-popup";
import EligibilityRunModalContent from "./EligibilityRunModalContent";
import InvoiceModalContent from "./InvoiceModalContent";

const AdminView = ({
  patient,
  therapists = [],
  onUpdateField,
  onSavePatient,
  onUpdatePatient,
  appt_details,
  onDeleteAccount
}) => {
  const { patient_id: patientID } = useParams();
  const [runEligibility, setRunEligibility] = useState(false);
  const onComplete = async (value, close = true) => {
    if (close) {
      setRunEligibility(false);
    }

    onUpdatePatient((prev) => ({
      ...prev,
      ...value,
    }));

    try {
      console.log(value);
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${window.server_url}/admin/patients/${patientID}`,
        {
          method: "PUT",
          body: JSON.stringify(value),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + token,
            Accept: "application/json",
          },
        }
      );
      if (!response.ok) return false;
      await response.json();
    } catch (e) {
      console.log(e);
    }
  };

  const counseling_options = patient.counseling_options
    ? patient.counseling_options.map((status) => {
        return (
          <option value={status} selected={status === patient.counseling}>
            {status}
          </option>
        );
      })
    : [];

  const invoicing_statuses = patient.invoicing_statuses
    ? patient.invoicing_statuses.map((status) => {
        return (
          <option value={status} selected={status === patient.invoicing_status}>
            {status}
          </option>
        );
      })
    : [];
  const invoice_tos = patient.invoice_tos
    ? patient.invoice_tos.map((status) => {
        return (
          <option value={status} selected={status === patient.invoice_to}>
            {status}
          </option>
        );
      })
    : [];
  const verification_statuses = patient.verification_statuses
    ? patient.verification_statuses.map((status) => {
        return (
          <option
            value={status}
            selected={status === patient.verification_status}
          >
            {status}
          </option>
        );
      })
    : [];
  const sec_verification_statuses = patient.verification_statuses
    ? patient.verification_statuses.map((status) => {
        return (
          <option
            value={status}
            selected={status === patient.sec_verification_status}
          >
            {status}
          </option>
        );
      })
    : [];
  const scheduling_statuses = patient.scheduling_statuses
    ? patient.scheduling_statuses.map((status) => {
        return (
          <option
            value={status}
            selected={status === patient.scheduling_status}
          >
            {status}
          </option>
        );
      })
    : [];
  const report_statuses = patient.report_statuses
    ? patient.report_statuses.map((status) => {
        return (
          <option value={status} selected={status === patient.report_status}>
            {status}
          </option>
        );
      })
    : [];
  const networks = patient.networks
    ? patient.networks.map((status) => {
        return (
          <option value={status} selected={status === patient.network}>
            {status}
          </option>
        );
      })
    : [];
  const sec_networks = patient.networks
    ? patient.networks.map((status) => {
        return (
          <option value={status} selected={status === patient.sec_network}>
            {status}
          </option>
        );
      })
    : [];
  const providerNetworks = patient.networks
    ? patient.networks.map((status) => {
        return (
          <option value={status} selected={status === patient.provider_network}>
            {status}
          </option>
        );
      })
    : [];
  const sec_provider_networks = patient.networks
    ? patient.networks.map((status) => {
        return (
          <option value={status} selected={status === patient.sec_provider_network}>
            {status}
          </option>
        );
      })
    : [];
  const groupNetworks = patient.networks
    ? patient.networks.map((status) => {
        return (
          <option value={status} selected={status === patient.group_network}>
            {status}
          </option>
        );
      })
    : [];
  const sec_groupNetworks = patient.networks
    ? patient.networks.map((status) => {
        return (
          <option value={status} selected={status === patient.sec_group_network}>
            {status}
          </option>
        );
      })
    : [];
  const deductibles = patient.deductibles
    ? patient.deductibles.map((status) => {
        return (
          <option value={status} selected={status === patient.deductible}>
            {status}
          </option>
        );
      })
    : [];
  const sec_deductibles = patient.deductibles
    ? patient.deductibles.map((status) => {
        return (
          <option value={status} selected={status === patient.sec_deductible}>
            {status}
          </option>
        );
      })
    : [];
  const insurances = patient.insurances
    ? patient.insurances.map((status) => {
        return (
          <option
            value={status}
            selected={patient.insurance.indexOf(status) > -1}
          >
            {status}
          </option>
        );
      })
    : [];
  const mental_healths = ["", "Yes", "No"].map((status) => {
    return (
      <option value={status} selected={status === patient.mental_health}>
        {status}
      </option>
    );
  });
  const sec_mental_healths = ["", "Yes", "No"].map((status) => {
    return (
      <option value={status} selected={status === patient.sec_mental_health}>
        {status}
      </option>
    );
  });
  const plan_types = ["", 'PPO', 'HMO', 'POS', 'EPO', 'Medicare', 'Medicaid', 'Medicare Advantage PPO', 'Medicare Advantage HMO', 'Other'].map((planType) => {
    return (
      <option value={planType} selected={planType === patient.plan_type}>
        {planType}
      </option>
    );
  });
  const sec_plan_types = ["", 'PPO', 'HMO', 'POS', 'EPO', 'Medicare', 'Medicare Supplement', 'Medicaid', 'Other'].map((planType) => {
    return (
      <option value={planType} selected={planType === patient.sec_plan_type}>
        {planType}
      </option>
    );
  });
  const telehealths = ["", "Yes", "No"].map((status) => {
    return (
      <option value={status} selected={status === patient.telehealth}>
        {status}
      </option>
    );
  });
  const sec_telehealths = ["", "Yes", "No"].map((status) => {
    return (
      <option value={status} selected={status === patient.sec_telehealth}>
        {status}
      </option>
    );
  });

  const oon_benefits = ["", "Yes", "No"].map((status) => {
    return (
      <option value={status} selected={status === patient.oon_benefits}>
        {status}
      </option>
    );
  });
  const sec_oon_benefits = ["", "Yes", "No"].map((status) => {
    return (
      <option value={status} selected={status === patient.sec_oon_benefits}>
        {status}
      </option>
    );
  });
  const submit_claims = ["", "Yes", "No", "Review"].map((status) => {
    return (
      <option value={status} selected={status === patient.submit_claim}>
        {status}
      </option>
    );
  });
  const sec_bill_secondarys = ["", "No", "Bill as secondary", "Bill as primary"].map((status) => {
    return (
      <option value={status} selected={status === patient.sec_bill_secondary}>
        {status}
      </option>
    );
  });
  const deductible_applies = ["", "Yes", "No"].map((status) => {
    return (
      <option value={status} selected={status === patient.deductible_applies}>
        {status}
      </option>
    );
  });
  const sec_deductible_applies = ["", "Yes", "No"].map((status) => {
    return (
      <option value={status} selected={status === patient.sec_deductible_applies}>
        {status}
      </option>
    );
  });
  const scs_procedures = patient.scs_procedures.map(status => {
    return <option value={status} selected={status === patient.scs_procedure}>{status}</option>
  });
  let cancellation_reason;
  if (["Cancelled", "No-Show"].indexOf(patient.scheduling_status) > -1) {
    let cancellation_reasons = patient.cancellation_reasons
      ? patient.cancellation_reasons.map((reason) => {
          return (
            <option
              value={reason}
              selected={reason === patient.cancellation_reason}
            >
              {reason}
            </option>
          );
        })
      : [];

    cancellation_reason = (
      <tr>
        <td>
          <strong>Cancellation reason</strong>
        </td>
        <td colspan="2">
          <select
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              onUpdateField("cancellation_reason", e.target.value);
            }}
          >
            {cancellation_reasons}
          </select>
        </td>
      </tr>
    );
  }
  let delete_account_button;

  if(patient.account_id && patient.registration_status !== 'Completed') {
    delete_account_button = (
      <button
        className="btn left"
        onClick={(e) => {
          e.preventDefault();
          if(window.confirm('Are you sure you want to delete this patient account?')) {
            onDeleteAccount(patient.account_id);
          }
        }}
      >
        Delete Account
      </button>
    );
  }

  let isPainPatient = false;
  const painProcedures = ['SCS', 'Spinal Cord Stimulator', 'Chronic Pain'];

  painProcedures.forEach(name => {
    if (patient.procedure.indexOf(name) > -1) isPainPatient = true;
  });

  return (
    <div className="page admin form detail">
      <h1>
        Patient Detail: {patient.first_name} {patient.last_name}
      </h1>
      <button
        className="btn left"
        onClick={(e) => {
          e.preventDefault();
          onSavePatient();
        }}
      >
        Save
      </button>
      <a className="btn left" href="/admin">
        Cancel
      </a>
      <button
        className="btn left"
        onClick={(e) => {
          e.preventDefault();
          onSavePatient(`/admin/admin_notes/${patientID}`, true);
        }}
      >
        Admin Notes
      </button>
      <a
        className={`btn left${patient.fullslate_id ? "" : " disabled"}`}
        href={`/admin/new_appointment/${patientID}`}
      >
        New Appointment
      </a>
      <a className="btn left" href={`/admin/reschedule/${patientID}`}>
        Reschedule
      </a>
      <button
        className="btn left"
        onClick={(e) => {
          e.preventDefault();
          setRunEligibility(true);
        }}
      >
        Run Eligibility
      </button>
      <Popup
        modal
        open={runEligibility}
        onClose={() => setRunEligibility(false)}
      >
        <EligibilityRunModalContent patient={patient} onComplete={onComplete} onClose={() => setRunEligibility(false)} />
      </Popup>
      <Popup
        modal
        trigger={(
          <button className="btn left">
            Invoice
          </button>
        )}
      >
        {close => (
          <>
            <InvoiceModalContent
              patient={patient}
              onUpdatePatient={onUpdatePatient}
              onClose={close}
            />
          </>
        )}
      </Popup>
      {delete_account_button}
      <div class="detail-area">
        <table className="fullwidth">
          <tbody>
            <tr>
              {[
                "Procedure",
                "Registration Status",
                "Invoicing Status",
                "Invoice To",
                "Amount Received",
                "Report Status",
                "Report Date",
                "Patient Notes",
                "APB Notes",
              ].map((item) => (
                <th key={item}>
                  <strong>{item}</strong>
                </th>
              ))}
            </tr>
            <tr>
              <td>
                <p>{patient.procedure}</p>
              </td>
              <td>
                <p>{patient.status}</p>
              </td>
              <td>
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("invoicing_status", e.target.value);
                  }}
                >
                  {invoicing_statuses}
                </select>
              </td>
              <td>
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("invoice_to", e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  {invoice_tos}
                </select>
              </td>
              <td>
                <input
                  type="text"
                  onChange={(e) =>
                    onUpdateField("cash_payment", e.target.value)
                  }
                  defaultValue={patient.cash_payment}
                />
              </td>
              <td>
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("report_status", e.target.value);
                  }}
                >
                  {report_statuses}
                </select>
                <input
                  style={{ display: "inline", marginTop: "10px" }}
                  type="checkbox"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("not_recommended", e.target.checked);
                  }}
                  defaultChecked={patient.not_recommended ? "checked" : ""}
                />
                &nbsp;
                <label style={{ display: "inline" }}>Not Recommended</label>
              </td>
              <td>
                <Cleave
                  options={{
                    date: true,
                    datePattern: ["m", "d", "Y"],
                  }}
                  placeholder="MM/DD/YYYY"
                  value={patient.report_date}
                  onBlur={(e) =>
                    onUpdateField(
                      "report_date",
                      moment(e.target.value).hour(10).format()
                    )
                  }
                />
              </td>
              <td>
                <p>{patient.patient_notes}</p>
              </td>
              <td>
                <input
                  type="text"
                  onChange={(e) => onUpdateField("apb_notes", e.target.value)}
                  defaultValue={patient.apb_notes}
                />
              </td>
            </tr>
            {isPainPatient &&
              <tr>
                <td>Pain Procedure</td>
                <td>
                  <select onClick={e => {e.stopPropagation()}} onChange={(e) => onUpdateField("scs_procedure", e.target.value)} >
                      {scs_procedures}
                    </select>
                </td>
              </tr>
            }
            <tr>
              <td>
                <p></p>
              </td>
            </tr>
           {appt_details}
           
            <tr>
              <td colspan="3">
                <h3>Demographics</h3>
              </td>
              <td colspan="3">
                <h3>Appointment Details</h3>
              </td>
            </tr>
            <tr>
              <td>
                <strong>First Name</strong>
              </td>
              <td colspan="5">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("first_name", e.target.value)}
                  defaultValue={patient.first_name}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Last Name</strong>
              </td>
              <td colspan="5">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("last_name", e.target.value)}
                  defaultValue={patient.last_name}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Phone</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("phone", e.target.value)}
                  defaultValue={patient.phone}
                />
              </td>
              <td>
                <strong>Date</strong>
              </td>
              <td colspan="2">
                <Cleave
                  options={{
                    date: true,
                    datePattern: ["m", "d", "Y"],
                  }}
                  placeholder="MM/DD/YYYY"
                  value={moment(patient.appointment_date_modified).format(
                    "MM/DD/YYYY"
                  )}
                  onBlur={(e) =>
                    onUpdateField(
                      "appointment_date_modified",
                      moment(e.target.value).format("YYYY-MM-DD")
                    )
                  }
                />
              </td>
              <td colspan="2">
                <input
                  style={{ display: "inline", marginTop: "10px" }}
                  type="checkbox"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("remove_patient", e.target.checked);
                  }}
                  defaultChecked={patient.remove_patient ? "checked" : ""}
                />
                &nbsp;
                <label style={{ display: "inline" }}>Remove</label>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Email</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("email", e.target.value)}
                  defaultValue={patient.email}
                />
              </td>
              <td>
                <strong>Time</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) =>
                    onUpdateField("appointment_time", e.target.value)
                  }
                  defaultValue={patient.appointment_time}
                />
              </td>
              <td colspan="2">
                <input
                  style={{ display: "inline", marginTop: "10px" }}
                  type="checkbox"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("do_not_contact", e.target.checked);
                  }}
                  defaultChecked={patient.do_not_contact ? "checked" : ""}
                />
                &nbsp;
                <label style={{ display: "inline" }}>Do not contact</label>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Address</strong>
              </td>
              <td colspan="2">
                {patient.address}
                <br />
                {patient.city}, {patient.state} {patient.zip}
              </td>
              <td>
                <strong>Therapist</strong>
              </td>
              <td colspan="2">
                <select
                  onChange={(e) => {
                    onUpdateField("therapist", e.target.value);
                  }}
                >
                  {(therapists || []).map((therapist) => {
                    return (
                      <option
                        value={
                          therapist.fullslate_id +
                          "|" +
                          therapist.first_name +
                          " " +
                          therapist.last_name
                        }
                        selected={
                          patient.therapist_id === therapist.fullslate_id
                        }
                      >
                        {therapist.first_name} {therapist.last_name}
                        {therapist.inactive === true ? " (inactive)" : ""}
                      </option>
                    );
                  })}
                </select>
              </td>
              <td>
                <strong>Authorization #</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) =>
                    onUpdateField("authorization_number", e.target.value)
                  }
                  defaultValue={patient.authorization_number}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Surgeon</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("surgeon", e.target.value)}
                  defaultValue={patient.surgeon}
                />
              </td>
              <td>
                <strong>Status</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("scheduling_status", e.target.value);
                  }}
                >
                  {scheduling_statuses}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Surgeon Phone</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) =>
                    onUpdateField("surgeon_phone", e.target.value)
                  }
                  defaultValue={patient.surgeon_phone}
                />
              </td>
              <td>
                <strong>Estimated Surgery Date</strong>
              </td>
              <td colspan="2">{patient.estimated_surgery_date}</td>
            </tr>
            <tr>
              <td>
                <strong>Age</strong>
              </td>
              <td colspan="2">{patient.age}</td>
              <td>
                <strong>Notes</strong>
              </td>
              <td colspan="2">{patient.appointment_notes}</td>
            </tr>
            <tr>
              <td>
                <strong>DOB</strong>
              </td>
              <td colspan="2">
                <Cleave
                  options={{
                    date: true,
                    delimiter: '-',
                    datePattern: ["Y", "m", "d"],
                  }}
                  placeholder="YYYY-MM-DD"
                  value={patient.dob_string}
                  onBlur={(e) =>
                    onUpdateField(
                      "dob_string",
                      e.target.value
                    )
                  }
                />
              </td>
              <td>
                <strong>Same provider requested?</strong>
              </td>
              <td colspan="2">{patient.same_provider}</td>
            </tr>
            <tr>
              <td>
                <strong>Gender</strong>
              </td>
              <td colspan="2">{patient.gender}</td>
              <td>
                <strong>No-show count</strong>
              </td>
              <td colspan="2">{patient.no_show_count}</td>
            </tr>
            <tr>
              <td>
                <strong>Language</strong>
              </td>
              <td colspan="2">{patient.patient_language}</td>
              <td>
                <strong>Session</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("session", e.target.value)}
                  defaultValue={patient.session}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Spanish language provider preferred</strong>
              </td>
              <td colspan="2">{patient.spanish_provider_preferred}</td>
              <td>
                <strong>Contact if earlier appointment available?</strong>
              </td>
              <td colspan="2">
                {patient.contact_if_earlier_appt_available ? "Yes" : "No"}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Counseling</strong>
              </td>
              <td colspan="5">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("counseling", e.target.value);
                  }}
                >
                  {counseling_options}
                </select>
              </td>
            </tr>
            {cancellation_reason}
            <tr>
              <td>
                <p></p>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <h3>Insurance</h3>
              </td>
              <td colspan="3">
                <h3>Clinic Details</h3>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Primary Insurance</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("insurance", e.target.value);
                  }}
                >
                  {insurances}
                </select>
              </td>
              <td>
                <strong>Practice Name</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) =>
                    onUpdateField("practice_name", e.target.value)
                  }
                  defaultValue={patient.practice_name}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Name as it appears on insurance card</strong>
              </td>
              <td colspan="5">{patient.name_on_card}</td>
            </tr>
            <tr>
              <td>
                <strong>Insurance #</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) =>
                    onUpdateField("insurance_number", e.target.value)
                  }
                  defaultValue={patient.insurance_number}
                />
              </td>
              <td>
                <strong>Practice ID</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("practice_id", e.target.value)}
                  defaultValue={patient.practice_id}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Customer Service #</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) =>
                    onUpdateField("insurance_phone", e.target.value)
                  }
                  defaultValue={patient.insurance_phone}
                />
              </td>
              <td>
                <strong>Surgeon</strong>
              </td>
              <td colspan="2">{patient.surgeon}</td>
            </tr>
            <tr>
              <td>
                <strong>Notes</strong>
              </td>
              <td colspan="2">{patient.insurance_notes}</td>
              <td>
                <strong>Phone #</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) =>
                    onUpdateField("clinic_phone", e.target.value)
                  }
                  defaultValue={patient.clinic_phone}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Out of state insurance?</strong>
              </td>
              <td colspan="2">{patient.out_of_state_insurance}</td>
              <td>
                <strong>Fax #</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("clinic_fax", e.target.value)}
                  defaultValue={patient.clinic_fax}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>BCBS Type</strong>
              </td>
              <td colspan="2">{patient.bcbs_type}</td>
              <td>
                <strong>Primary Contact</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) =>
                    onUpdateField("clinic_primary_contact", e.target.value)
                  }
                  defaultValue={patient.clinic_primary_contact}
                />
              </td>
            </tr>
            <tr>
              <td colspan="3"></td>
              <td>
                <strong>Referral</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("referral", e.target.value)}
                  defaultValue={patient.referral}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p></p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Secondary Insurance</strong>
              </td>
              <td colspan="2">{patient.secondary_insurance}</td>
            </tr>
            <tr>
              <td>
                <strong>Secondary Insurance #</strong>
              </td>
              <td colspan="2">{patient.secondary_insurance_number}</td>
            </tr>
            <tr>
              <td>
                <strong>Customer Service #</strong>
              </td>
              <td colspan="2">{patient.secondary_insurance_phone}</td>
            </tr>
            <tr>
              <td>
                <strong>Notes</strong>
              </td>
              <td colspan="2">{patient.secondary_insurance_notes}</td>
            </tr>
            <tr>
              <td>
                <p></p>
              </td>
            </tr>
            <tr>
              <td>
                <h3>Verification</h3>
              </td>
            </tr>
            <tr>
              <td><strong>Payor Name</strong></td>
              <td>{patient.payor_name}</td>
              <td><strong>Sec Payor Name</strong></td>
              <td>{patient.sec_payor_name}</td>
            </tr>
            <tr>
              <td><strong>Contact #</strong></td>
              <td>{patient.contact_number}</td>
              <td><strong>Sec Contact #</strong></td>
              <td>{patient.sec_contact_number}</td>
            </tr>
            <tr>
              <td><strong>Rep Name</strong></td>
              <td>{patient.rep_name}</td>
              <td><strong>Sec Rep Name</strong></td>
              <td>{patient.sec_rep_name}</td>
            </tr>
            <tr>
              <td><strong>Reference #</strong></td>
              <td>{patient.reference}</td>
              <td><strong>Sec Reference #</strong></td>
              <td>{patient.sec_reference}</td>
            </tr>
            <tr>
              <td><strong>Advantum Team Member</strong></td>
              <td>{patient.advantum_team_member}</td>
              <td><strong>Sec Advantum Team Member</strong></td>
              <td>{patient.sec_advantum_team_member}</td>
            </tr>
            <tr>
              <td>
                <strong>Plan Type</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("plan_type", e.target.value);
                  }}
                >
                  {plan_types}
                </select>
              </td>
              <td>
                <strong>Sec. Plan Type</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("sec_plan_type", e.target.value);
                  }}
                >
                  {sec_plan_types}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Mental Health</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("mental_health", e.target.value);
                  }}
                >
                  {mental_healths}
                </select>
              </td>
              <td>
                <strong>Sec. Mental Health</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("sec_mental_health", e.target.value);
                  }}
                >
                  {sec_mental_healths}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Telehealth</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("telehealth", e.target.value);
                  }}
                >
                  {telehealths}
                </select>
              </td>
              <td>
                <strong>Sec. Telehealth</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("sec_telehealth", e.target.value);
                  }}
                >
                  {sec_telehealths}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Verification Status</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("verification_status", e.target.value);
                  }}
                >
                  {verification_statuses}
                </select>
              </td>
              <td>
                <strong>Sec. Verification Status</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("sec_verification_status", e.target.value);
                  }}
                >
                  {sec_verification_statuses}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Verification Date</strong>
              </td>
              <td colspan="2">
                <Cleave
                  options={{
                    date: true,
                    datePattern: ["m", "d", "Y"],
                  }}
                  placeholder="MM/DD/YYYY"
                  value={patient.verification_date}
                  onBlur={(e) =>
                    onUpdateField(
                      "verification_date",
                      moment(e.target.value).hour(10).format()
                    )
                  }
                />
              </td>
              {/* <td>
                <strong>Sec. Verification Date</strong>
              </td>
              <td colspan="2">
                <Cleave
                  options={{
                    date: true,
                    datePattern: ["m", "d", "Y"],
                  }}
                  placeholder="MM/DD/YYYY"
                  value={patient.sec_verification_date}
                  onBlur={(e) =>
                    onUpdateField(
                      "sec_verification_date",
                      moment(e.target.value).hour(10).format()
                    )
                  }
                />
              </td> */}
            </tr>
            <tr>
              <td>
                <strong>Network Status</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("network", e.target.value);
                  }}
                >
                  {networks}
                </select>
              </td>
              <td>
                <strong>Sec. Network Status</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("sec_network", e.target.value);
                  }}
                >
                  {sec_networks}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Provider Network Status</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("provider_network", e.target.value);
                  }}
                >
                  {providerNetworks}
                </select>
              </td>
              <td>
                <strong>Sec. Provider Network Status</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("sec_provider_network", e.target.value);
                  }}
                >
                  {sec_provider_networks}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Group Network Status</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("group_network", e.target.value);
                  }}
                >
                  {groupNetworks}
                </select>
              </td>
              <td>
                <strong>Sec. Group Network Status</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("sec_group_network", e.target.value);
                  }}
                >
                  {sec_groupNetworks}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Deductible</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("deductible", e.target.value);
                  }}
                >
                  {deductibles}
                </select>
              </td>
              <td>
                <strong>Sec. Deductible</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("sec_deductible", e.target.value);
                  }}
                >
                  {sec_deductibles}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Deductible Remaining</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) =>
                    onUpdateField("deductible_remaining", e.target.value)
                  }
                  value={patient.deductible_remaining}
                />
              </td>
              <td>
                <strong>Sec. Deductible Remaining</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) =>
                    onUpdateField("sec_deductible_remaining", e.target.value)
                  }
                  value={patient.sec_deductible_remaining}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Individual Deductible Remaining</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) =>
                    onUpdateField("ind_deductible_remaining", e.target.value)
                  }
                  value={patient.ind_deductible_remaining}
                />
              </td>
              <td>
                <strong>Sec. Individual Deductible Remaining</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) =>
                    onUpdateField("sec_ind_deductible_remaining", e.target.value)
                  }
                  value={patient.sec_ind_deductible_remaining}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Family Deductible Remaining</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) =>
                    onUpdateField("fam_deductible_remaining", e.target.value)
                  }
                  value={patient.fam_deductible_remaining}
                />
              </td>
              <td>
                <strong>Sec. Family Deductible Remaining</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) =>
                    onUpdateField("sec_fam_deductible_remaining", e.target.value)
                  }
                  value={patient.sec_fam_deductible_remaining}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Deductible Annual Reset Date</strong>
              </td>
              <td colspan="2">
                <Cleave
                  options={{
                    date: true,
                    datePattern: ["m", "d", "Y"],
                  }}
                  placeholder="MM/DD/YYYY"
                  value={patient.deductible_annual_reset_date}
                  onBlur={(e) =>
                    onUpdateField(
                      "deductible_annual_reset_date",
                      e.target.value
                    )
                  }
                />
              </td>
              <td>
                <strong>Sec. Deductible Annual Reset Date</strong>
              </td>
              <td colspan="2">
                <Cleave
                  options={{
                    date: true,
                    datePattern: ["m", "d", "Y"],
                  }}
                  placeholder="MM/DD/YYYY"
                  value={patient.sec_deductible_annual_reset_date}
                  onBlur={(e) =>
                    onUpdateField(
                      "sec_deductible_annual_reset_date",
                      e.target.value
                    )
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Deductible Applies</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("deductible_applies", e.target.value);
                  }}
                >
                  {deductible_applies}
                </select>
              </td>
              <td>
                <strong>Sec. Deductible Applies</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("sec_deductible_applies", e.target.value);
                  }}
                >
                  {sec_deductible_applies}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <strong>OON Benefits</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("oon_benefits", e.target.value);
                  }}
                >
                  {oon_benefits}
                </select>
              </td>
              <td>
                <strong>Sec. OON Benefits</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("sec_oon_benefits", e.target.value);
                  }}
                >
                  {sec_oon_benefits}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Submit Claim</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("submit_claim", e.target.value);
                  }}
                >
                  {submit_claims}
                </select>
              </td>
              <td>
                <strong>Sec. Bill Insurance</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("sec_bill_secondary", e.target.value);
                  }}
                >
                  {sec_bill_secondarys}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <strong>OOP Max Applies</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("oop_max_applies", e.target.value);
                  }}
                >
                  {["", "Yes", "No"].map((item) => (
                    <option value={item} selected={item === patient.oop_max_applies}>
                      {item}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <strong>Sec. OOP Max Applies</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("sec_oop_max_applies", e.target.value);
                  }}
                >
                  {["", "Yes", "No"].map((item) => (
                    <option value={item} selected={item === patient.sec_oop_max_applies}>
                      {item}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <strong>OOP Max Remaining</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("oop_max", e.target.value)}
                  value={patient.oop_max}
                />
              </td>
              <td>
                <strong>Sec. OOP Max Remaining</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("oop_max", e.target.value)}
                  value={patient.sec_oop_max}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Individual OOP Max Remaining</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("ind_oop_max", e.target.value)}
                  value={patient.ind_oop_max}
                />
              </td>
              <td>
                <strong>Sec. Individual OOP Max Remaining</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("sec_ind_oop_max", e.target.value)}
                  value={patient.sec_ind_oop_max}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Family OOP Max Remaining</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("fam_oop_max", e.target.value)}
                  value={patient.fam_oop_max}
                />
              </td>
              <td>
                <strong>Sec. Family OOP Max Remaining</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("sec_fam_oop_max", e.target.value)}
                  value={patient.sec_fam_oop_max}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Allowable Amount</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) =>
                    onUpdateField("allowable_amount", e.target.value)
                  }
                  value={patient.allowable_amount}
                />
              </td>
              <td>
                <strong>Sec. Allowable Amount</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) =>
                    onUpdateField("sec_allowable_amount", e.target.value)
                  }
                  value={patient.sec_allowable_amount}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Claim To</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("claim_to", e.target.value)}
                  value={patient.claim_to}
                />
              </td>
              <td>
                <strong>Sec. Claim To</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("sec_claim_to", e.target.value)}
                  value={patient.sec_claim_to}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Copay Applies</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("copay_applies", e.target.value);
                  }}
                >
                  {["", "Yes", "No"].map((item) => (
                    <option value={item} selected={item === patient.copay_applies}>
                      {item}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <strong>Sec. Copay Applies</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("sec_copay_applies", e.target.value);
                  }}
                >
                  {["", "Yes", "No"].map((item) => (
                    <option value={item} selected={item === patient.sec_copay_applies}>
                      {item}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Copay</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("ins_copay", e.target.value)}
                  value={patient.ins_copay}
                />
              </td>
              <td>
                <strong>Sec. Copay</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("sec_ins_copay", e.target.value)}
                  value={patient.sec_ins_copay}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Co-insurance Applies</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("co_ins_applies", e.target.value);
                  }}
                >
                  {["", "Yes", "No"].map((item) => (
                    <option value={item} selected={item === patient.co_ins_applies}>
                      {item}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <strong>Sec. Co-insurance Applies</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("sec_co_ins_applies", e.target.value);
                  }}
                >
                  {["", "Yes", "No"].map((item) => (
                    <option value={item} selected={item === patient.sec_co_ins_applies}>
                      {item}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Co-insurance(%)</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("co_insurance", e.target.value)}
                  value={patient.co_insurance}
                />
              </td>
              <td>
                <strong>Sec. Co-insurance(%)</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("sec_co_insurance", e.target.value)}
                  value={patient.sec_co_insurance}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Amount Due</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("copay", e.target.value)}
                  value={patient.copay}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Invoice URL</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("invoice_url", e.target.value)}
                  value={patient.invoice_url}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Verification Notes</strong>
              </td>
              <td colspan="2">
                <textarea
                  onChange={(e) =>
                    onUpdateField("verification_notes", e.target.value)
                  }
                  value={patient.verification_notes}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminView;
