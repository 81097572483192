import React from 'react';
import moment from 'moment';
import { CSVLink, CSVDownload } from "react-csv";
import parsePatient from './parsePatient';
import DatePicker from "react-datepicker";
import parseAssessment from './parseAssessment';
import Login from './Login';
import Loading from './Loading';
import Downloading from './Downloading';
import { site } from '../config/config'

import Cleave from 'cleave.js/react';

function download_stream(blob, filename) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  // the filename you want
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

//import { Link } from "react-router-dom";

class PatientList extends React.Component {
  constructor(props){
    let state = {};

    state.filter = localStorage.getItem('patients.filter');
    state.page = localStorage.getItem('patients.page');
    state.asc = props.report_view ? 'desc' : localStorage.getItem('patients.asc');
    state.sort = props.report_view ? 'appointment_date_modified' : localStorage.getItem('patients.sort');

    if(state.page) state.page = parseInt(state.page);
    state.asc = (state.asc && state.asc === 'true');

    if(state.filter === 'null') state.filter = null;

    // Object.keys(state).forEach((index) => {
    //   console.log(index, state[index], typeof state[index])
    //   if(state[index] && typeof state[index] != 'undefined') state[index] = JSON.parse(state[index]);
    // });

    super(props);
    this.state = {
    	filter: state.filter || null,
      dob_filter: state.dob_filter || null,
      filterHighPriority: false,
      filterSCS: false,
      filterEmptyVerification: false,
      filterInvoicingStatus: null,
      filterReportStatus: null,
      patients: null,
      patient_data: null,
      patient_data_select: null,
      loading: false,
      pages: 0,
      page: state.page || 0,
    	asc: state.asc || false,
      level: null,
      sort: state.sort || 'createdAt'
    }
    this.uploadRef = React.createRef();

    this.getPatients = this.getPatients.bind(this);
    this.filterResults = this.filterResults.bind(this);
    this.filterDOB = this.filterDOB.bind(this);
    //this.colsSort = this.colsSort.bind(this);
    this.sort = this.sort.bind(this);
    this.showPatient = this.showPatient.bind(this);
    this.updatePatient = this.updatePatient.bind(this);
    this.setStorage = this.setStorage.bind(this);
    this.downloadPatientData = this.downloadPatientData.bind(this);
    this.downloadPatientDataBilling = this.downloadPatientDataBilling.bind(this);
    this.selectPatientDataBilling = this.selectPatientDataBilling.bind(this);
    this.selectPatientData = this.selectPatientData.bind(this);
    this.uploadInsuranceReimbursement = this.uploadInsuranceReimbursement.bind(this);
    this.uploadInsuranceReimbursementSubmit = this.uploadInsuranceReimbursementSubmit.bind(this);
    this.downloadAssessmentData = this.downloadAssessmentData.bind(this);
    this.filterHighPriority = this.filterHighPriority.bind(this);
    this.filterSCS = this.filterSCS.bind(this);
    this.filterEmptyVerification = this.filterEmptyVerification.bind(this);

    localStorage.removeItem('patients.filter');
    localStorage.removeItem('patients.page');
    localStorage.removeItem('patients.asc');
    localStorage.removeItem('patients.sort');
  }
  updatePatient(patient_id, data) {
    let token = localStorage.getItem('token'),
        that = this;

    fetch(window.server_url + '/admin/patients/' + patient_id, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
          if (!response.ok) {
              //localStorage.removeItem('token');
              window.location.href = '/admin';
              throw Error(response.statusText);
              return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log(res);
        console.log('b');
        window.removeEventListener('beforeunload', window.preventLeave);
        //this.setState({loading: false});
        
        // else if (res.user.token) {
        //   localStorage.setItem('token',res.user.token);
        // }
        
      })
      .catch(error => console.log(error) );  

    window.addEventListener('beforeunload', window.preventLeave);
    console.log('a');
    //this.setState({loading: true});
  }
  uploadInsuranceReimbursementSubmit() {
    let token = localStorage.getItem('token'),
        that = this,
        data = new FormData(),
        file = this.uploadRef.current;

    // console.log('uploading file', file.files[0]);

    data.append('file', file.files[0]);
    // console.log('uploading file', data);


    fetch(window.server_url + '/admin/import_insurance_reimbursement', {
        method: 'POST',
        body: data,
        headers: {
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
          if (!response.ok) {
              //localStorage.removeItem('token');
              window.location.href = '/admin';
              throw Error(response.statusText);
              return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log(res);
        console.log('b');
        if(res.error) return that.setState({loading: false, upload_error: res.error});
        else if(res.success) that.setState({loading: false, upload_success: res.number});
        
      })
      .catch(error => console.log(error) );  
    this.setState({loading: true, upload_error: false, upload_success: false});
  }
  componentDidMount() {

    if(!this.state.patients) this.getPatients();

    document.body.className += ' ' + 'fix';
    document.getElementsByTagName( 'html' )[0].className += ' ' + 'fix';


  }
  setStorage() {
    console.log('setting...', this.state.sort, this.state.filter, this.state.asc, this.state.page);
    localStorage.setItem('patients.sort', this.state.sort);
    localStorage.setItem('patients.filter', this.state.filter);
    localStorage.setItem('patients.asc', this.state.asc);
    localStorage.setItem('patients.page', this.state.page);
    //callback();
  }
  componentWillUnmount() {
    this.setStorage();

    console.log('setting...', this.state);
    document.body.className = document.body.className.replace(' fix', '');
    document.getElementsByTagName( 'html' )[0].className = document.getElementsByTagName( 'html' )[0].className.replace(' fix', '');
    window.removeEventListener('beforeunload', window.preventLeave);
  }
  filterResults(value) {
  	this.setState({filter: value, page: 0});
  }
  filterDOB(value) {
    this.setState({dob_filter: value, page: 0});
  }
  filterHighPriority(value) {
    this.setState({filterHighPriority: value, page: 0});
  }
  filterSCS(value) {
    this.setState({filterSCS: value, page: 0});
  }
  filterEmptyVerification(value) {
    this.setState({filterEmptyVerification: value, page: 0});
  }
  showPatient(id) {
    this.setStorage();
    if(this.props.report_view) window.location.href = '/admin/patient_report/' + id;
    else window.location.href = '/admin/patient/' + id;
  }
	getPatients() {
    let token = localStorage.getItem('token'),
        that = this,
        search = '',
        dob_filter = '',
        reports = '',
        statuses = '';

    if(this.state.filter) search = '&search='+this.state.filter;
    if(this.state.dob_filter) dob_filter = '&dob_filter='+this.state.dob_filter;
    if(this.props.report_view) reports = '&reports_only=true';
    if(this.state.filterInvoicingStatus) {
      statuses += '&invoicing_status='+this.state.filterInvoicingStatus;
    }    
    if(this.state.filterReportStatus) {
      statuses += '&report_status='+this.state.filterReportStatus;
    }


    fetch(window.server_url + '/admin/patients?page='+this.state.page+'&sort='+this.state.sort+'&asc='+this.state.asc+'&high_priority='+this.state.filterHighPriority+'&scs='+this.state.filterSCS+'&no_verification='+this.state.filterEmptyVerification+search+statuses+reports+dob_filter, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
          if (!response.ok) {
              localStorage.removeItem('token');
              window.location.href = '/admin';
              throw Error(response.statusText);
              return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log(res);
        if(!res.data) {
          localStorage.removeItem('token');
          window.location.href = '/admin';
          return false;
        } else {
          let _patients = res.data.map(patient => {
            return parsePatient(patient);
          })
          that.setState({
            patients: _patients,
            pages: res.pages,
            level: res.level
          })
        }
        // else if (res.user.token) {
        //   localStorage.setItem('token',res.user.token);
        // }
        
      })
      .catch(error => console.log(error) );  
  }

  downloadPatientData() {
    let token = localStorage.getItem('token'),
    		that = this,
        from = moment(this.state.export_from).format('YYYY-MM-DD'),
        to = moment(this.state.export_to).format('YYYY-MM-DD');

    fetch(window.server_url + '/admin/patient_data?from='+from+'&to='+to, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (res) {
        if(!res) {
        	localStorage.removeItem('token');
        	window.location.href = '/admin';
        	return false;
        } else {
          res.blob().then(blob => download_stream(blob))
        	that.setState({
            loading: false,
            downloading: true
        	})
        }        
      })
      .catch(error => console.log(error) );  
      this.setState({loading: true});
  }
  selectPatientDataBilling() {
    this.setState({patient_data_select: true})
  }
  selectPatientData() {
    this.setState({patient_data_select_export: true})
  }
  uploadInsuranceReimbursement() {
    this.setState({upload_insurance_reimbursement: true})
  }
  downloadPatientDataBilling() {
    let token = localStorage.getItem('token'),
        that = this,
        search = '',
        from = moment(this.state.export_from).format('YYYY-MM-DD'),
        to = moment(this.state.export_to).format('YYYY-MM-DD');

    if(this.state.filter) search = '&search='+this.state.filter;


    fetch(window.server_url + '/admin/patient_data_billing?from='+from+'&to='+to, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
          if (!response.ok) {
              localStorage.removeItem('token');
              window.location.href = '/admin';
              throw Error(response.statusText);
              return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log(res);
        if(!res.data) {
          localStorage.removeItem('token');
          window.location.href = '/admin';
          return false;
        } else {
          let _patient_data = res.data.map(patient => {
            // console.log('total_time_seconds', patient.total_time_seconds)
            return parsePatient(patient).billing_data_horizontal;
          })
          that.setState({
            patient_data: _patient_data,
            level: res.level,
            loading: false
          })
        }
        // else if (res.user.token) {
        //   localStorage.setItem('token',res.user.token);
        // }
        
      })
      .catch(error => console.log(error) );  
      this.setState({loading: true});
  }
  downloadAssessmentData() {
    let token = localStorage.getItem('token'),
        that = this,
        search = '';


    fetch(window.server_url + '/admin/assessment_data', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
          if (!response.ok) {
              localStorage.removeItem('token');
              window.location.href = '/admin';
              throw Error(response.statusText);
              return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log(res);
        if(!res.data) {
          localStorage.removeItem('token');
          window.location.href = '/admin';
          return false;
        } else {
          let _assessment_data = res.data.map(assessment => {
            console.log('assessment:',assessment);
            return parseAssessment(assessment).data_horizontal;
          })
          that.setState({
            assessment_data: _assessment_data,
            level: res.level,
            loading: false
          })
        }
        // else if (res.user.token) {
        //   localStorage.setItem('token',res.user.token);
        // }
        
      })
      .catch(error => console.log(error) );  
      this.setState({loading: true});
  }
  
  componentDidUpdate(prevProps, prevState) {
    if(this.state.sort != prevState.sort || this.state.asc != prevState.asc || this.state.filter != prevState.filter || this.state.page != prevState.page || this.state.filterHighPriority != prevState.filterHighPriority || this.state.filterSCS != prevState.filterSCS || this.state.filterEmptyVerification != prevState.filterEmptyVerification || this.state.filterInvoicingStatus != prevState.filterInvoicingStatus || this.state.filterReportStatus != prevState.filterReportStatus) {
      this.getPatients();
    }
  }
  sort(col) {
    if(this.state.sort === col) {
      this.setState({
        asc: !this.state.asc
      })
    } else {
      this.setState({
        sort: col,
        asc: false
      })
    }
  }
  render() {
    let is_sa = true; // #TODO: set this per permissions
    let is_admin = false; // #TODO: set this per permissions

    if(!this.state.patients) return '';
    else {
    	let patients = [];
      let columns;

      if(this.props.report_view === true && ['super_admin', 'admin', 'advantum'].indexOf(this.state.level) > -1) columns = {
        first_name: {
          name: 'First Name',
          type: 'string'
        
        },
        last_name: {
          name: 'Last Name',
          type: 'string'
        
        },
        invoicing_status: {
          name: 'Invoicing Status',
          type: 'string',
          // editable: true,
          // select_property: 'invoicing_statuses',
          color_codes: [
            'sent',
            'denied'
          ]
        },
        appointment_date_modified_nice: {
          name: 'Appointment Date',
          type: 'date'
        },
        therapist: {
          name: 'Provider',
          type: 'string'
        },
        scheduling_status: {
          name: 'Interview Status',
          type: 'string',
          color_codes: [
            'sent',
            'denied'
          ]
        },
        surgeon: {
          name: 'Surgeon',
          type: 'string'
        },
        report_status: {
          name: 'Report Status',
          type: 'string',
          color_codes: [
           // 'sent',
            'denied'
          ]
        },
        apb_notes: {
          name: 'APB Notes',
          type: 'string',
        }

      } 
      else if(this.state.level === 'verification') columns = {
        first_name: {
          name: 'First Name',
          type: 'string'
        },
        last_name: {
          name: 'Last Name',
          type: 'string'
        },
        email: {
          name: 'Email',
          type: 'string'
        },
        phone: {
          name: 'Phone',
          type: 'string'
        },
        createdAt: {
          name: 'Intake Date',
          type: 'date'
        },
        appointment_date_modified_nice: {
          name: 'Appointment Date',
          type: 'date',
          width: '70px'
        },
        status: {
          name: 'Registration Status',
          type: 'string',
          color_codes: [
            'sent',
            'denied'
          ],
          width: '70px'

        },
        verification_status: {
          name: 'Verification Status',
          // editable: true,
          // select_property: 'verification_statuses',
          type: 'string',
          width: '70px'
        },
        invoicing_status: {
          name: 'Invoicing Status',
          type: 'string',
          editable: true,
          select_property: 'invoicing_statuses',
          color_codes: [
            'sent',
            'denied'
          ],
          width: '70px'
        },
        copay: {
          name: 'Co-pay',
          // editable: true,
          narrow: true,
          type: 'string',
          width: '50px'
        },
        cash_payment: {
          name: 'Amount Paid',
          editable: true,
          narrow: true,
          type: 'string',
        },
        scheduling_status: {
          name: 'Interview Status',
          type: 'string',
          color_codes: [
            'sent',
            'denied'
          ]
        },
        report_status: {
          name: 'Report Status',
          type: 'string',
          editable: true,
          select_property: 'report_statuses',
        },
        apb_notes: {
          name: 'APB Notes',
          editable: true,
          type: 'string',
        }

      };

      else if(this.state.level === 'api') columns = {
      
        first_name: {
          name: 'First Name',
          type: 'string'
        
        },
        last_name: {
          name: 'Last Name',
          type: 'string'
        
        },
        createdAt: {
          name: 'Intake Date',
          type: 'date'
        },
        surgeon: {
          name: 'Surgeon',
          type: 'string'
        },
        status: {
          name: 'Registration Status',
          type: 'string',
          color_codes: [
            'sent',
            'denied'
          ]
        },
        verification_status: {
          name: 'Verification Status',
          type: 'string',
        },
        appointment_date_modified_nice: {
          name: 'Service Date',
          type: 'date',
        },
        scheduling_status: {
          name: 'Interview Status',
          type: 'string',
          color_codes: [
            'sent',
            'denied'
          ]
        },
        invoicing_status: {
          name: 'Invoicing Status',
          type: 'string',
          // editable: true,
          // select_property: 'invoicing_statuses',
          color_codes: [
            'sent',
            'denied'
          ]
        },
        report_status: {
          name: 'Report Status',
          type: 'string',
          color_codes: [
           // 'sent',
            'denied'
          ]
        },
        // report_date: {
        //   name: 'Report Date',
        //   type: 'date',
        // },
      
      }

      else if(this.state.level === 'super_admin') columns = {
        first_name: {
          name: 'First Name',
          type: 'string'
        
        },
        last_name: {
          name: 'Last Name',
          type: 'string'
        
        },
        createdAt: {
          name: 'Intake Date',
          type: 'date'
        },
        surgeon: {
          name: 'Surgeon',
          type: 'string'
        },
        status: {
          name: 'Registration Status',
          type: 'string',
          color_codes: [
            'sent',
            'denied'
          ]
        },
        verification_status: {
          name: 'Verification Status',
          type: 'string',
        },
        copay: {
          name: 'Amount Due',
          type: 'string',
        },       
        appointment_date_modified_nice: {
          name: 'Service Date',
          type: 'date',
        },
        therapist: {
          name: 'Therapist',
          type: 'string',
        },
        scheduling_status: {
          name: 'Interview Status',
          type: 'string',
          color_codes: [
            'sent',
            'denied'
          ]
        },
        invoicing_status: {
          name: 'Invoicing Status',
          type: 'string',
          // editable: true,
          // select_property: 'invoicing_statuses',
          color_codes: [
            'sent',
            'denied'
          ]
        },
        cash_payment: {
          name: 'Amount Received',
          type: 'string',
        },
        report_status: {
          name: 'Report Status',
          type: 'string',
          color_codes: [
           // 'sent',
            'denied'
          ]
        },
        // report_date: {
        //   name: 'Report Date',
        //   type: 'date',
        // },
        apb_notes: {
          name: 'APB Notes',
          type: 'string',
        }
      };

      else if(this.state.level === 'admin') columns = {
        first_name: {
          name: 'First Name',
          type: 'string'
        
        },
        last_name: {
          name: 'Last Name',
          type: 'string'
        
        },
        procedure_code: {
          name: 'Procedure',
          type: 'string'
        
        },
        createdAt: {
          name: 'Intake Date',
          type: 'date'
        },
        surgeon: {
          name: 'Surgeon',
          type: 'string'
        },
        status: {
          name: 'Registration Status',
          type: 'string',
          color_codes: [
            'sent',
            'denied'
          ]
        },

        verification_status: {
          name: 'Verification Status',
          type: 'string',
          color_codes: [
            'verified'
          ]
        },
        copay: {
          name: 'Amount Due',
          type: 'string',
        },       
        appointment_date_modified_nice: {
          name: 'Service Date',
          type: 'date',
        },
        therapist: {
          name: 'Therapist',
          type: 'string',
        },
        scheduling_status: {
          name: 'Interview Status',
          type: 'string',
          color_codes: [
            'sent',
            'denied'
          ]
        },
        invoicing_status: {
          name: 'Invoicing Status',
          type: 'string',
          // editable: true,
          // select_property: 'invoicing_statuses',
          color_codes: [
            'sent',
            'denied'
          ]
        },
        cash_payment: {
          name: 'Amount Received',
          type: 'string',
        },
        report_status: {
          name: 'Report Status',
          type: 'string',
          color_codes: [
           // 'sent',
            'denied'
          ]
        },
        patient_notes: {
          name: 'Patient Notes',
          type: 'string',
        },
        apb_notes: {
          name: 'APB Notes',
          type: 'string',
        }
      };
       else if(this.state.level === 'advantum') columns = {
        first_name: {
          name: 'First Name',
          type: 'string'
        
        },
        last_name: {
          name: 'Last Name',
          type: 'string'
        
        },
        date_registered: {
          name: 'Registration Date',
          type: 'date'
        },
        appointment_date_modified_nice: {
          name: 'Service Date',
          type: 'date',
        },
        therapist: {
          name: 'Provider',
          type: 'string',
        },
        verification_status: {
          name: 'Verification Status',
          // editable: true,
          // select_property: 'verification_statuses',
          type: 'string',
          width: '70px'
        },
        scheduling_status: {
          name: 'Interview Status',
          type: 'string',
          color_codes: [
            'sent',
            'denied'
          ]
        },
        insurance: {
          name: 'Insurance Name',
          type: 'string',
          width: '200px'
        },
        secondary_insurance_added: {
          name: 'Secondary Insurance?',
          type: 'string',
        },
        verification_notes: {
          name: 'Verification Notes',
          type: 'string',
        },
        apb_notes: {
          name: 'APB Notes',
          type: 'string',
        }
      };

      else if(this.state.level === 'therapist' || this.state.level === 'super_therapist') columns = {
        first_name: {
          name: 'First Name',
          type: 'string'
        
        },
        last_name: {
          name: 'Last Name',
          type: 'string'
        
        },
        therapist: {
          name: 'Therapist',
          type: 'string',
        },
        appointment_date_modified_nice: {
          name: 'Scheduled Date',
          type: 'date',
        },
        appointment_time: {
          name: 'Scheduled Time',
          type: 'date',
        },
        phone: {
          name: 'Phone',
          type: 'string'
        },
        state: {
          name: 'State',
          type: 'string'
        },
        dob_string: {
          name: 'DOB',
          type: 'string'
        },
        procedure: {
          name: 'Procedure',
          type: 'string'
        },
        invoicing_status: {
          name: 'Invoicing Status',
          type: 'string',
          color_codes: [
            'sent',
            'denied'
          ]
        },
        scheduling_status: {
          name: 'Interview Status',
          type: 'string',
          color_codes: [
            'sent',
            'denied'
          ]
        },
        report_status: {
          name: 'Report Status',
          type: 'string',
          color_codes: [
            //'sent',
            'denied'
          ]
        },
        apb_notes: {
          name: 'APB Notes',
          type: 'string',
        }

      };
      
    	let that = this;
    	this.state.patients.forEach((patient, patient_index) => {
    		
        if(!patient) return;


    		// if(that.state.filter && that.state.filter.trim() !== '') {
    		// 	let shards = that.state.filter.toLowerCase().split(' ');
    		// 	let ok = true;
    		// 	shards.forEach(function(shard) {
      //       let shard_match = false;
      //       Object.keys(columns).forEach(function(column_name) {
      //         if(patient[column_name] && patient[column_name].toLowerCase().indexOf(shard) > -1) shard_match = true;
      //       })
	    	// 		if(!shard_match) ok = false;
	    	// 	});
	    	// 	if(!ok) return;
    		// }
        let _index = 0;
        let cols = Object.keys(columns).map((name) => {
          let className = '';
          let column = columns[name];
          let style = {};
          _index ++;

          if(column.color_codes) {
            column.color_codes.forEach(code => {
              if(patient[name] && patient[name].toLowerCase().indexOf(code) > -1) {
                className += ' ' + code;
              }
              if(this.state.level === 'admin' && name === 'verification_status' && patient.verification_status === 'Verified' && (!patient.invoicing_status || patient.invoicing_status === '')) {
                className += ' sent';
              }
            });
          }
          if(column.width) style = {width: column.width};
          if(column.narrow) className += ' narrow';
          if(column.editable) {
            if(patient[column.select_property]) {

              let statuses = patient[column.select_property].map(status => {
                return <option value={status} selected={status === patient[name]}>{status}</option>
              });

              return (
                <td key={_index} style={style} className={className}>
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {
                    let data = {};
                    data[name] = e.target.value;
                    this.updatePatient(patient._id, data);
                  }}>
                    {statuses}
                  </select>
                </td>
              );
            } else {
              return (
                <td key={_index} style={style} className={className}>
                  <input type="text" defaultValue={patient[name]} onClick={e => {e.stopPropagation()}} onChange={e => {
                    let data = {};
                    data[name] = e.target.value;
                    this.updatePatient(patient._id, data);
                  }} />
                </td>
              );

            }
          }
          return <td style={style} className={className}>{patient[name]}</td>;
        });

        _index ++;

  		  patients.push(
          <tr key={patient._id} className="item" onClick={e => {this.showPatient(patient._id)}}>
            <td key={_index}><input 
                  type="checkbox" 
                  onClick={e => {e.stopPropagation()}} onChange={e => {
                    let data = {};
                    data.high_priority = e.target.checked;
                    this.updatePatient(patient._id, data);
                  }} 
                  defaultChecked={patient.high_priority ? 'checked' : ''} /></td>
            {cols}

            {
              /* <td>
              <select onClick={e => {e.stopPropagation()}} onChange={e => {changeInvoicingStatus(patient._id, e.target.value)}}>
                {statuses}
              </select>
            </td> */ 
          }
          </tr>);
    	});
      let col_titles = Object.keys(columns).map((name) => {
        let className = '';
        let column = columns[name];
        console.log(column)

        return <th className={that.state.sort === name ? 'sort' : ''} onClick={e => {that.sort(name)}}>{column.name} {that.state.sort === name ? that.state.asc === true ? '^' : 'v' : ''}</th>
      });
      let nav = [];
      let limit = 20;
      let half = Math.floor(limit / 2);

      for(let x = 0; x < this.state.pages; x++) {
        let collapse_end = this.state.page < this.state.pages - half && x > this.state.page + half && x < this.state.pages - 4;
        let collapse_start = this.state.page >= half && x < this.state.page - half && x > 3
        let ellipsis_end = this.state.page < this.state.pages - half && x === this.state.pages - 4;
        let ellipsis_start = this.state.page >= half && x === 4;

        if(this.state.pages > limit) {
          if(ellipsis_end || ellipsis_start) {
            nav.push(<span>...</span>);
          }
          if(collapse_end) {
            continue;
          }
          if(collapse_start) {
            continue;
          }
        }
        if(x === this.state.page) nav.push(<span class="current">{x + 1}</span>)
        else nav.push(<a href="#" className="nav-page" onClick={(e) => {e.preventDefault(); this.setState({page: x})}}>{x + 1}</a>)
      }
      let invoicing_statuses = [
              '',
              'Paid',
              'Partial',
              'Unpaid'
          ].map(status => {
        return <option value={status} selected={status === this.state.filterInvoicingStatus}>{status}</option>
      });
      let report_statuses = [
            '',
            'Review',
            'Hold',
            'Complete',
            'Sent'
          ].map(status => {
        return <option value={status} selected={status === this.state.filterReportStatus}>{status}</option>
      });
      let report_filters;

      if(this.props.report_view) {
        report_filters = [
          <label>Invoicing Status:</label>,
          <select onClick={e => {e.stopPropagation()}} onChange={e => {this.setState({filterInvoicingStatus: e.target.value}); }}>
            {invoicing_statuses}
          </select>,
          <label>Report Status:</label>,
          <select onClick={e => {e.stopPropagation()}} onChange={e => {this.setState({filterReportStatus: e.target.value})}}>
            {report_statuses}
          </select>
        ];

      }

      let csv_download;
      if(this.state.loading) return <Loading />;
      if(this.state.downloading) return <Downloading />;

      if(this.state.level === 'super_admin') csv_download = [
        <a class="btn download-all-btn" onClick={this.selectPatientData}>Download all patient data as CSV</a>,
        <a class="btn download-billing-btn" onClick={this.selectPatientDataBilling}>Download patient billing data as CSV</a>,
        <a class="btn upload-insurance-btn" onClick={this.uploadInsuranceReimbursement}>Upload insurance reimbursement data as CSV</a>
      ]



      if(this.state.patient_data) return (
        <div className="page admin form">
          <h1>Download ready:</h1>
          <CSVLink class="btn" data={this.state.patient_data} target="_blank">Download all patient data</CSVLink>
        </div>
      )

      else if(this.state.assessment_data) return (
        <div className="page admin form">
          <h1>Download ready:</h1>
          <CSVLink class="btn" data={this.state.assessment_data} target="_blank">Download all assessment data</CSVLink>
        </div>
      )
      else if(this.state.patient_data_select) return (
        <div className="page admin form">
          <h1>Select date range to export billing data:</h1>
          <form>
            <div className="date-range">
              <label>From:</label>
              <DatePicker maxDate={new Date()} autoComplete="new-password" selected={this.state.export_from} onChange={value => this.setState({export_from: value})} />
              <br/><br/>
              <label>To:</label>
              <DatePicker maxDate={new Date()} autoComplete="new-password" selected={this.state.export_to} onChange={value => this.setState({export_to: value})} />
            </div>

            <a onClick={this.downloadPatientDataBilling} class={`btn ${this.state.export_from && this.state.export_to ? '' : 'disabled'}`}>Download</a>
          </form>
        </div>
      )
      else if(this.state.patient_data_select_export) return (
        <div className="page admin form">
          <h1>Select date range to export:</h1>
          <form>
            <div className="date-range">
              <label>From:</label>
              <DatePicker maxDate={new Date()} autoComplete="new-password" selected={this.state.export_from} onChange={value => this.setState({export_from: value})} />
              <br/><br/>
              <label>To:</label>
              <DatePicker maxDate={new Date()} autoComplete="new-password" selected={this.state.export_to} onChange={value => this.setState({export_to: value})} />
            </div>

            <a onClick={this.downloadPatientData} class={`btn ${this.state.export_from && this.state.export_to ? '' : 'disabled'}`}>Download</a>
          </form>
        </div>
      )
      else if(this.state.upload_insurance_reimbursement) {
        let upload_error, upload_success;

        if(this.state.upload_error) {
          upload_error = <p>Error: {this.state.upload_error}</p>;
        }
        if(this.state.upload_success) {
          upload_error = <p>{this.state.upload_success} patients updated successfully.</p>;
        }
        return (
          <div className="page admin form">
            <h1>Upload patient insurance reimbursement as CSV:</h1>
            <form>
                <p>Upload a CSV file with fields: email, appointment_date_modified, insurance_reimbursement (first line as field name)</p>
                <input type="file" ref={this.uploadRef} />
              <a onClick={this.uploadInsuranceReimbursementSubmit} class="btn">Submit</a>
            </form>
            {upload_error}
            {upload_success}
          </div> 
        )
      }
      else return (
	    	<div className="page admin form">
		    	<h1>{this.props.report_view ? 'Patient Reports' : 'Patients'}</h1>
          {csv_download}
          <form className="report-search-form" onSubmit={e => {e.preventDefault(); this.getPatients()}}>
            <input type="text" class="search-field" name="search" placeholder="Filter patients" defaultValue={this.state.filter} onChange={e => {this.filterResults(e.target.value)}} />
            <Cleave 
              class="search-field"
              options={{
                date: true,
                delimiter: '-',
                datePattern: ['Y', 'm', 'd']
              }} 
              placeholder="DOB (yyyy-mm-dd)"
              name="dob_filter"
              onChange={e => {this.filterDOB(e.target.value)}} />
            <input type="submit" class="btn" value="Search" />
            {report_filters}
            <div class="filter-scs">
            <span><input type="checkbox" onChange={e => {this.filterSCS(e.target.checked)}} /><label>Only SCS Patients</label></span>
            {this.state.level === 'advantum' ? <span><input type="checkbox" onChange={e => {this.filterEmptyVerification(e.target.checked)}} /><label>No Verification Status</label></span> : null}
            </div>

          </form>
		    	<br/>
          <div className="results-area">
          <table id="results">
            <tbody>
              <tr className="header">
                <th><span style={{float: 'left'}}><input type="checkbox" onChange={e => {this.filterHighPriority(e.target.checked)}} />&nbsp;</span> HP</th>
                {col_titles}
              </tr>
    		    	{patients}
            </tbody>
          </table>
          </div>    
          <div class="pages">
            Page: {nav}
          </div>

		    </div>
	    );
    }
  }
}

export default PatientList;